<template>
  <div class="container">
    <div class="board">
      <div class="board-top">
        <div class="item">
          <h5>50</h5>
          <p>员工总数</p>
        </div>
        <div class="item">
          <h5>2</h5>
          <p>回国休假</p>
        </div>
        <div class="item">
          <h5>50</h5>
          <p>隔离人员</p>
        </div>
        <div class="item">
          <h5>50</h5>
          <p>隔离人员 <br />体温异常</p>
        </div>
        <div class="item">
          <h5>50</h5>
          <p>风险预警</p>
        </div>
      </div>
      <div class="board-mid">
        <h5>在场</h5>
        <section class="board-data">
          <div class="item">
            <h6>2</h6>
            <p>管理</p>
          </div>
          <div class="item">
            <h6>2</h6>
            <p>劳务</p>
          </div>
          <div class="item">
            <h6>2</h6>
            <p>其他</p>
          </div>
          <div class="item">
            <h6>2</h6>
            <p>已接种疫苗</p>
          </div>
        </section>
      </div>
    </div>
    <section class="content">
      <div class="tabbar">
        <span class="active">海外</span>
        <span>中国</span>
        <span>刚果</span>
      </div>
      <div class="cont">
        <div id="map"></div>
        <div class="tags">
          <span>机构</span>
          <span>国别</span>
          <span>项目</span>
        </div>
        <ul>
          <li class="card" v-for="item in [1, 2, 3, 4]">
            <h5 class="card-title">
              <p>中西非公司</p>
              <span>隔离人员：2</span>
            </h5>
            <div class="cart-content">
              <h6 class="sub-title">在场</h6>
              <section class="board-data">
                <div class="item">
                  <h6>2</h6>
                  <p>管理</p>
                </div>
                <div class="item">
                  <h6>2</h6>
                  <p>劳务</p>
                </div>
                <div class="item">
                  <h6>2</h6>
                  <p>其他</p>
                </div>
                <div class="item">
                  <h6>2</h6>
                  <p>已接种疫苗</p>
                </div>
              </section>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { tripLevelList } from "@/api/api";
export default {
  data() {
    return {};
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      tripLevelList().then((res) => {
        console.log(res);
      });
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0.1rem;
}
.board {
  padding: 0.12rem 0.08rem;
  background: rgba(79, 119, 170, 0.89);
  border-radius: 10px;
}
.board-top {
  display: flex;
  justify-content: space-between;
}
.board-top .item {
  flex: 1;
  color: #fff;
  text-align: center;
}
.board-top .item h5 {
  font-size: 0.35rem;
  font-weight: normal;
}
.board-top .item p {
  font-size: 0.12rem;
}
.board-mid {
  background: #fff;
  border-radius: 12px;
  padding: 0.11rem 0.13rem;
  margin-top: 0.11rem;
}
.board-mid h5,
.sub-title {
  font-weight: normal;
  font-size: 0.14rem;
}
.board-data {
  display: flex;
  justify-content: space-between;
}
.board-data .item {
  text-align: center;
}

.board-data .item h6 {
  font-weight: normal;
  color: #ff8050;
  font-size: 0.18rem;
}
.board-data .item p {
  font-size: 0.12rem;
  width: 0.7rem;
  height: 0.18rem;
  line-height: 0.18rem;
  border-radius: 0.7rem;
  background: #cccccc;
  color: rgba(51, 51, 51, 0.63);
}
.content {
  margin-top: 0.18rem;
}
.tabbar {
  display: flex;
  justify-content: space-between;
}
.tabbar span {
  line-height: 0.4rem;
  flex: 1;
  text-align: center;
  background: #f2f2f2;
}
.tabbar span:not(:last-child) {
  border-right: 1px solid #707070;
}
.tabbar span.active {
  background: #fff;
}
.cont {
  padding: 0.2rem 0.1rem;
  background: #fff;
}
.tags {
  display: flex;
}
.tags span {
  background: rgba(97, 132, 178, 0.22);
  width: 0.68rem;
  height: 0.24rem;
  border-radius: 0.12rem;
  text-align: center;
  color: #2f7dcd;
  font-size: 0.14rem;
  margin-right: 0.1rem;
}
ul {
  list-style: none;
  margin-top: 0.14rem;
}
.card {
  padding: 0.15rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.14rem;
}
.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #021412;
  padding-bottom: 0.06rem;
  font-weight: normal;
  border-bottom: 1px solid rgba(112, 112, 112, 0.31);
}
.card-title span {
  font-size: 0.12rem;
  color: #890908;
}
.cart-content {
  padding-top: 0.06rem;
}
</style>